@tailwind base;
@tailwind components;
@tailwind utilities;




.nav-down {
  animation: navDown .7s;
}

@keyframes navDown {
  0% { transform: translateY(-50%); opacity: 0;}
  30% {opacity: 0;}
  100% { transform: translateY(0); opacity: 1;}
}

.to-left {
  animation: Toleft 1s;
}

@keyframes Toleft {
  0% { transform: translateX(100%); opacity: 0;}
  100% { transform: translateX(0); opacity: 1;}
}

.to-right {
  animation: Toright 1s;
}

@keyframes Toright {
  0% { transform: translateX(-100%); opacity: 0;}
  100% { transform: translateX(0); opacity: 1;}
}

.fadeoutc {
  animation: fadeout .6s;
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.fadeinph {
  animation: fadeinph 1s;
  
}
@keyframes fadeinph {
  from { opacity: 0;}
  to   { opacity: 1; }
}

.fadeinp {
  animation: fadeinc 0.7s;
}

.fadeoutp {
  animation-delay: 1000ms;
  animation: fadeoutc 1s;
}

@keyframes fadeoutc {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.fadeinc {
  animation: fadeinc 1s;
}

@keyframes fadeinc {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.fadein {
  animation: fadein .7s;
}

@keyframes fadein {
  0% { opacity: 0; transform: scale(0.5);}
  100% { opacity: 1; transform: scale(1);}
}

.move-up {
  animation: moveUp 1s;
}



@keyframes moveUp {
  0% { transform: translateY(100%); opacity: 0;}
  100% { transform: translateY(0); opacity: 1;}
}

.App {
  text-align: center;
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.proj_content {
  min-height: 50vh;
}

.about_page {
  height: 150vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-docker::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.video-content {
  z-index: 2;

}

.fadein {
  -webkit-transition: all 0.67s;
  -moz-transition: all 0.67s;
  -ms-transition: all 0.67s;
  -o-transition: all 0.67s;
  transition: all 0.67s;
  opacity: 1;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

.playfair {
  font-family: 'Playfair Display', serif;
}
.experience_section .title {
  width: 70%;
}

.navbar-transparent {
  background:rgba(x,x,x,0.3);
}